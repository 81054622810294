
.c-avatar-name {
  text-align: center;
  opacity: 0;
  transition: opacity 200ms linear;
  width: 100%;
  position: absolute;
  top: 100%;
  padding: 2px 0;

}

.c-avatar-wrapper {
  margin-left: 20px;

  .q-avatar-container {
    margin-left: -20px;
    transition: all 250ms linear;
    cursor: pointer;
    z-index: 1;

    .c-avatar {
      box-sizing: content-box;
      border: 2px solid white;
      background-color: white;
    }

    &:hover {
      z-index: 4 !important;
    }

    &:nth-child(4) {
      z-index: 1;
    }

    &:last-child {
      z-index: 0;
    }

    &.open, &:hover {
      scale: 1.8;
      z-index: 3;

      .c-avatar-name {
        opacity: 1;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}


.list-enter-active {
  animation: dropIn .4s ease-out;
}

.list-leave-active {
  transition: all .4s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}

@keyframes dropIn {
  from {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
