@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  border: 1px $grey-3 solid;
  border-radius: 18px;
  background: $grey-1;
}

