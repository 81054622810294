
.c-swiper-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  .q-btn {
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.3 !important;
    }
  }
}
