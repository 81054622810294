

.c-wrapper {
  transition: all 500ms linear !important;
}

.item {
  transition: 1s ease-in-out width;
}

.slide-fade-enter-active {
  transition: all 350ms ease-out;
}

.slide-fade-leave-active {
  transition: all 350ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(-30px);
  filter: blur(5px);
}

.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
  filter: blur(5px);
}

