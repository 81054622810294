@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.c-highlighted {
  box-shadow: 0 0 1rem 0.1rem $primary;
}

.c-card-link-wrapper {
  text-decoration: unset;
}

