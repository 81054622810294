@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';



.trust-logos {
  filter: grayscale(100%);
  opacity: 0.5;
}


.c-shake-hands {
  animation-name: shake-hands-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  //transform-origin: 0 50%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  transition-timing-function: ease-in-out;
}

@keyframes shake-hands-animation {
  0%, 100% {
    transform: translateY(-6px);
  }
  25%, 75% {
    transform: translateY(7px);
  }
  50% {
    transform: translateY(0);
  }
}


$box-count: 6;

.c-standout {
  animation: wave 6s ease-in-out infinite;
}

/* Wave animation */
@keyframes wave {
  0%, 45%, 55% {
    transform: rotate(0);
  }
  48% {
    transform: rotate(3deg); /* Adjust height of wave */
  }
  52% {
    transform: rotate(-3deg); /* Adjust height of wave */
  }
}

/* Loop through each box and assign an increasing delay */
@for $i from 1 through $box-count {
  .c-standout:nth-child(#{$i}) {
    animation-delay: #{($i - 1) * 1}s;
  }
}

.highlighted-title {
  background-color: $primary;
  color: #FFFFFF;
  padding: 8px 12px;
  box-decoration-break: clone;
  display: inline;
  border-radius: 4px;
  line-height: 4.8rem;
}

.market-emoji-box {
  font-size: 3rem;
  position: absolute;
  animation: jumpToMiddleAndEnd 4s ease-in-out infinite;
}

@keyframes jumpToMiddleAndEnd {
  0% {
    left: 50px;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  20% {
    transform: translateX(-50%) translateY(-80px);
  }
  35% {
    opacity: 1;
  }
  40%, 100% {
    left: calc(100% - 50px);
    transform: translateX(-100%);
    opacity: 0;
  }
}


.c-section-bg-1 {
  background: adjust-color($secondary, $lightness: 45%);
}

.c-section-bg-2 {
  background: adjust-color($secondary, $lightness: 30%);
}

.c-section-bg-3 {
  background: adjust-color($secondary, $lightness: 45%);
}
