// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #242424;
$secondary: #00cfc4;
$tertiary: #008a7f;
$accent: #2b3b38;

$dark: #242424;
$dark-page: #1D1D1D;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

$text-black: #000000;
$light: #f5f5f5;

$h1:        (size: 3rem,     line-height: 3rem,     letter-spacing: -.01562em, weight: 500) !default;
$h2:        (size: 1.875rem,  line-height: 1.875rem,  letter-spacing: -.00833em, weight: 500) !default;
$h3:        (size: 1.5rem,     line-height: 1.5rem, letter-spacing: normal,    weight: 500) !default;
$h4:        (size: 1.0625rem, line-height: 1.0625rem,   letter-spacing: .00735em,  weight: 500) !default;
$h5:        (size: 0.75rem,   line-height: 0.75rem,     letter-spacing: normal,    weight: 500) !default;
$h6:        (size: 0.625rem,  line-height: 0.625rem,     letter-spacing: .0125em,   weight: 500) !default;
