@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.profile-name-wrapper {
  .name-wrapper a {
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
    }
  }
}

.verified-badge {
  border-radius: 50%;
  display: flex;
  color: white;
  background-color: $secondary;
  aspect-ratio: 1;
  height: 15px;
  align-items: center;
  justify-content: center;

  & > i {
    margin-top: 2px;
  }
}

.c-link-underline {
  text-decoration: unset;

  &:hover {
    .name-wrapper {
      text-decoration: underline;
    }
  }
}
